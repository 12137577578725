export function initVideoCard() {
  function loadPlayer(imgEl) {
    console.debug("I will replace with a video player:", imgEl);
    var url = imgEl.closest('[data-video-url]').getAttribute('data-video-url');
    var playerIframe = document.createElement('iframe');
    playerIframe.setAttribute('src', url + '?embed');
    playerIframe.setAttribute('scrolling', 'no');
    playerIframe.setAttribute('allowfullscreen', 'allowfullscreen');
    playerIframe.setAttribute('width', imgEl.getBoundingClientRect().width);
    playerIframe.setAttribute('height', imgEl.getBoundingClientRect().height);
    playerIframe.setAttribute('style', 'border: 0');
    imgEl.closest('.card__image').append(playerIframe);
    imgEl.closest('.card__image').addEventListener('mouseenter', () => {
      imgEl.closest('a').style.zIndex = "0";
    })
  }
  document.addEventListener('lazyloaded', function (ev) {
    // NB: closest doesn't work on IE
    if (ev.target.closest('[data-video-url]') !== null) {
      loadPlayer(ev.target);
    }
  });
}
