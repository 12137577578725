function replaceSrc(originalSrc, newURL) {
  return originalSrc.replace(/url=[^&]+/, "url=" + newURL);
}

export function initGalleryCard () {
  $(document).ready(function () {
    $("[data-gallery-url]").each(function (i, el) {
      var $el = $(el);
      var galleryUrl = $el.attr("data-gallery-url").replace(/^https?:\/\//, '');
      var imageProperty = $el.hasClass("card--vertical") ? "vertical" : "horizontal";
      $.get("/gallery_images/" + galleryUrl, function (data) {
        var galleryLength = data["gallery_images"].length;
        $el.find(".overlay__number").html("+" + galleryLength);
        $el.find("picture").each(function(i, picture) {
          if (i < galleryLength) {
            $(picture).find("source").each(function (j, source) {
              var $source = $(source);
              var srcset = $source.attr("srcset");
              if (srcset) {
                srcset = replaceSrc(srcset, encodeURIComponent(data["gallery_images"][i][imageProperty]));
                $source.attr("srcset", srcset);
              }
              var dataSrcset = $source.attr("data-srcset");
              if (dataSrcset) {
                dataSrcset = replaceSrc(dataSrcset, encodeURIComponent(data["gallery_images"][i][imageProperty]));
                $source.attr("data-srcset", dataSrcset);
              }
            })
          }
        });
        $el.find("img").each(function(i, img) {
          if (i < galleryLength) {
            var $img = $(img);
            var src = $img.attr("src");
            if (src) {
              src = replaceSrc(src, encodeURIComponent(data["gallery_images"][i][imageProperty]));
              $img.attr("src", src);
            }
          }
        });
      })
    });
  });
}
